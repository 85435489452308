<template>
  <v-content>
    <v-container>
      <v-form ref="form">
        <v-row align="center" justify="center">
          <v-col :cols="12" :sm="12">
            <span class="text-left gray--text fontheader">Account Information</span>
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              prepend-inner-icon="perm_identity"
              placeholder="อีเมล"
              v-model="email"
              type="email"
              :rules="emailRules"
              required
              solo
            ></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              prepend-inner-icon="phone"
              placeholder="เบอร์โทรศัพท์"
              type="number"
              maxlength="10"
              :rules="telRules"
              required
              v-model="tel"
              solo
            ></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="12">
            <span class="text-left gray--text fontheader">Personal Information</span>
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <v-text-field
              v-model="name"
              prepend-inner-icon="perm_identity"
              placeholder="ชื่อ"
              :rules="nameRules"
              solo
            ></v-text-field>
          </v-col>
          <v-col :cols="6" :sm="6" class="py-0">
            <v-text-field
              prepend-inner-icon="perm_identity"
              placeholder="นามสกุล"
              required
              v-model="lastname"
              :rules="lastnameRules"
              solo
            ></v-text-field>
          </v-col>
          <v-col :cols="12" :sm="12" class="py-0">
            <v-text-field
              prepend-inner-icon="credit_card"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              placeholder="เลขบัตรประชาชน"
              type="number"
              maxlength="13"
              v-model="idcard"
              :rules="[checkIdCard]"
              required
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <Button title="ยืนยัน" :func="sendData"></Button>
      </v-form>
    </v-container>
  </v-content>
</template>
<script>
import Button from '../components/Button'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
    Button,
  },
  data() {
    return {
      valid: false,
      email: '',
      emailRules: [v => !!v || 'ป้อนอีเมล', v => /.+@.+\..+/.test(v) || 'อีเมลไม่ถูกต้อง'],
      name: '',
      nameRules: [v => !!v || 'ป้อนชื่อ'],
      lastname: '',
      lastnameRules: [v => !!v || 'ป้อนนามสกุล'],
      tel: '',
      telRules: [
        v => !!v || 'ป้อนเบอร์โทรศัพท์',
        v => /^0[0-9]{9}$/im.test(v) || 'เบอร์โทรศัพท์ไม่ถูกต้อง',
      ],
      idcard: '',
    }
  },
  methods: {
    async sendData() {
      try {
        const self = this
        const validateResult = self.$refs.form.validate()
        if (validateResult) {
          self.$loading(true)
          const res = await self.$axios({
            method: 'POST',
            url: '/api/account/register',
            data: {
              first_name: self.name,
              last_name: self.lastname,
              mobile_phone: self.tel,
              email: self.email,
              social_id: self.$store.state.tokenId,
            },
          })
          self.$loading(false)
          if (res.data.status === 'success') {
            self.$modal.show({
              title: res.data.message,
              isOne: true,
              onConfirm: () => self.$router.push('/listshop'),
              onClose: () => self.$router.push('/listshop'),
            })
          } else {
            self.$modal.show({
              title: `ERROR: ${res.data.message}`,
              isOne: true,
            })
          }
        }
      } catch (e) {
        self.$modal.show({
          title: `ERROR: ${e}`,
          isOne: true,
        })
        self.$loading(false)
      }
    },
    checkIdCard() {
      const self = this
      if (self.idcard === '') return 'ป้อนเลขบัตรประชาชน'
      let i = 0
      let sum = 0
      for (i = 0, sum = 0; i < 12; i++) {
        sum += parseInt(self.idcard.charAt(i)) * (13 - i)
      }
      const mod = sum % 11
      const check = (11 - mod) % 10

      if (check === parseInt(self.idcard.charAt(12))) return true
      else return 'เลขบัตรประชาชนไม่ถูกต้อง'
    },
  },
  computed: {
    ...mapState(['tokenId']),
  },
}
</script>
<style lang="scss"></style>
