<template>
  <div>
    <v-btn x-large block @click="func" color="primary3" dark :loading="loading">
      <v-icon left v-if="icon"> {{ icon }} </v-icon>
      {{ title }}
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      default: '',
    },
    func: {
      type: Function,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState(['loading']),
  },
}
</script>

<style lang="scss" scoped>
.v-btn {
  position: fixed;
  bottom: 0px;
  right: 0px;
}
</style>
